import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '@app/shared/services/helper.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
export const EXCLUDE_FROM_INTERCEPTOR = new HttpContextToken<boolean>(
  () => false
);
@Injectable()
export class DataUpdateInterceptorService implements HttpInterceptor {
  methodTypes = ['PUT', 'PATCH', 'POST', 'DELETE'];
  excludedUrls = [
    'https://talk.perspio.dev/assets/v3/socketdata/negotiate?negotiateVersion=1',
    'https://talk.perspio.io/assets/v3/socketdata/negotiate?negotiateVersion=1',
    'https://talk.perspio.dev/connectors/v3/socketdata/negotiate?negotiateVersion=1',
    'https://talk.perspio.io/connectors/v3/socketdata/negotiate?negotiateVersion=1',
    'https://signalr-dev-ae-01.service.signalr.net/client/negotiate?hub=telemetry&negotiateVersion=1',
    'https://signalr-prod-ae-01.service.signalr.net/client/negotiate?hub=telemetry&negotiateVersion=1',
    'https://signalr-dev-ae-01.service.signalr.net/client/negotiate?hub=events&negotiateVersion=1',
    'https://signalr-prod-ae-01.service.signalr.net/client/negotiate?hub=events&negotiateVersion=1',
    'https://talk.perspio.dev/users/v3/logs',
    'https://talk.perspio.io/users/v3/logs',
    'https://talk.perspio.dev/workflows/v3/schema',
    'https://talk.perspio.dev/workflows/v3/schema?extended=true',
    'https://talk.perspio.io/workflows/v3/schema',
    'https://talk.perspio.io/workflows/v3/schema?extended=true',
    'https://graph.microsoft.com/v1.0/me/extensions',
    'https://talk.perspio.dev/workflows/v3/assets',
    'https://talk.perspio.io/workflows/v3/assets',
    'https://talk.perspio.dev/workflows/v3/metadata',
    'https://talk.perspio.dev/assets/v3/types2',
    'https://talk.perspio.dev/workflows/v3/schema2',
    'https://talk.perspio.io/workflows/v3/metadata',
    'https://talk.perspio.io/assets/v3/types2',
    'https://talk.perspio.io/workflows/v3/schema2',
  ];
  constructor(private readonly helperService: HelperService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const shouldProcess = this.processCheck(request);
    const shouldProcessError = this.processCheckError(request);

    if (shouldProcess) {
      this.helperService?.isLoading.next(true);
    }

    return next.handle(request).pipe(
      // handle errors
      catchError((error: HttpErrorResponse) => {
        if (shouldProcessError) {
          if (error.error instanceof ErrorEvent) {
            console.log(error.error);
          } else {
            this.helperService?.isLoading.next(false);
            this.helperService?.updateError.next([
              {
                type: 'updateFailure',
                message: `Error!`,
                detail: this.getErrorMessage(error),
              },
            ]);
            setTimeout(() => {
              this.helperService?.updateError.next(false);
            }, 10);
          }
        } else if (request.method === 'GET') {
          this.helperService?.updateError.next([
            this.getErrorText(
              error?.status,
              request?.url?.replace(/https?:\/\/[^/]+/i, '')
            ),
          ]);
        }
        return throwError(() => new Error('Interceptor error'));
      }),
      // handle success
      tap((data) => {
        if (shouldProcess) {
          if (data instanceof HttpResponse) {
            if (data.status === 204 || data.status === 200) {
              this.helperService?.updateSuccess.next(true);
              this.helperService?.isLoading.next(false);
              setTimeout(() => {
                this.helperService?.updateSuccess.next([
                  {
                    type: 'saveSuccess',
                    message: `Success!`,
                    detail:
                      'Data ' +
                      (request.method === 'DELETE' ? 'deleted' : 'saved') +
                      ' successfully.',
                  },
                ]);
              }, 10);
            } else if (data.status === 202) {
              this.helperService?.updateSuccess.next(true);
              this.helperService?.isLoading.next(false);
              setTimeout(() => {
                if (request.url.includes(' /users')) {
                  this.helperService?.updateSuccess.next([
                    {
                      type: 'saveSuccess',
                      message: `Success!`,
                      detail:
                        'Your request to create a new user is being processed.',
                    },
                  ]);
                }

                if (request.url.includes('/importassetstask')) {
                  this.helperService?.updateSuccess.next([
                    {
                      type: 'saveSuccess',
                      message: `Success!`,
                      detail: 'Your CSV file upload is being processed.',
                    },
                  ]);
                }
              }, 10);
            }
          }
        }
      })
    );
  }

  processCheck(request) {
    let exclude = false;
    if (request?.context?.get(EXCLUDE_FROM_INTERCEPTOR) === true) {
      exclude = true;
    }

    return (
      !exclude &&
      this.methodTypes.includes(request?.method) &&
      !this.excludedUrls.includes(request?.url)
    );
  }

  private getErrorMessage(error: any): string {
    if (error.url.includes('importassetstask')) {
      return error.error;
    }

    if (error?.error?.statusCode === 401) {
      return 'Not authorised!';
    }
    return 'Something went wrong. Please try again later.';
  }

  processCheckError(request) {
    let exclude = false;
    if (request?.context?.get(EXCLUDE_FROM_INTERCEPTOR) === true) {
      exclude = true;
    }

    return !exclude && this.methodTypes.includes(request?.method);
  }

  getErrorText(code, url): any {
    switch (code) {
      case 404:
        return {
          type: 'getError',
          message: `Not found!`,
          detail: url,
        };

      case 405:
        return {
          type: 'getError',
          message: `Not allowed!`,
          detail: url,
        };

      case 408:
        return {
          type: 'getError',
          message: `Timeout!`,
          detail: url,
        };

      case 500:
        return {
          type: 'getError',
          message: `Internal server error!`,
          detail: url,
        };

      case 429:
        return {
          type: 'getError',
          message: `Throttled!`,
          detail: url,
        };

      case 401:
        return {
          type: 'getError',
          message: `Not authorised!`,
          detail: url,
        };

      case 403:
        return {
          type: 'getError',
          message: `Forbidded!`,
          detail: url,
        };

      default:
        return {
          type: 'getError',
          message: `Error!`,
          detail: url,
        };
    }
  }
}
